<template>
  <ModalLayout
    v-model="isOpen"
    content-class="ExportTasksLinkDialog"
    big-header
    :no-footer="!searchQuery || searchTasksRes == null"
    :max-width="800"
    :min-height="444"
    max-body-height="530px"
    padding="24px 28px"
    @click:close="isOpen = false"
  >
    <div class="ExportTasksLinkDialog__content">
      <div class="ExportTasksLinkDialog__summary text-truncate">
        <div class="ExportTasksLinkDialog__meta">
          <span class="ExportTasksLinkDialog__meta-label">{{ $t('integration.Integration') }}</span>
          <span
            class="d-inline-flex align-center mr-10"
            v-text="integration && integration.name"
          />

          <span class="ExportTasksLinkDialog__meta-label">{{ $t('integration.Project') }}</span>
          <span
            class="d-inline-flex align-center mr-10"
            v-text="(jiraProject && `[${jiraProject.key}] ${jiraProject.name}`) || $t('integration.UnknownProject')"
          />

          <span class="ExportTasksLinkDialog__meta-label">{{ $t('integration.IssueType') }}</span>
          <span class="d-inline-flex align-center">
            <img
              v-if="taskMeta.taskTypeIconUrl"
              :alt="taskMeta.taskTypeName"
              :src="taskMeta.taskTypeIconUrl"
              class="mr-2"
            >
            <v-icon
              v-else
              :size="16"
              class="mr-2"
              style="margin-top: -2px"
              v-text="'mdi-help-circle-outline'"
            />
            <span v-text="taskMeta.taskTypeName" />
          </span>
        </div>
        <h1
          class="ExportTasksLinkDialog__title"
          v-text="$t('integration.FindExistingTask')"
        />
      </div>

      <AppTextField
        v-model="searchQuery"
        outlined
        dense
        hide-details
        autofocus
        margins-with-hidden-details="mb-5"
        prepend-inner-icon="mdi-magnify"
        :loading="searching"
        :disabled="linking"
        :placeholder="$t('integration.TaskNameOrId')"
      />

      <div
        v-if="searchTasksRes && !searchTasksRes.length && !searching && !!searchQuery"
        v-text="$t('integration.NothingFound')"
      />

      <v-card
        v-for="task in searchTasksRes"
        :key="task.key"
        class="ExportTasksLinkDialog__task-card"
        :class="{
          'ExportTasksLinkDialog__task-card--selected': selectedTaskKey === task.key,
          'ExportTasksLinkDialog__task-card--already-linked': task.alreadyLinked,
        }"
        elevation="0"
        :ripple="task.alreadyLinked
          ? false
          : selectedTaskKey === task.key
            ? { class: 'white--text' }
            : { class: 'app-ripple' }"
        height="64"
        outlined
        :color="selectedTaskKey === task.key ? '#EBF5FF' : '#FAFAFA'"
        style="border-color: #E3E8F6"
        :disabled="task.alreadyLinked || linking || searching"
        @click="selectedTaskKey = selectedTaskKey === task.key ? null : task.key"
      >
        <div v-text="task.key" />
        <div
          class="mx-3"
          v-text="'•'"
        />
        <div v-text="task.summary" />
        <div
          v-if="task.alreadyLinked"
          class="ExportTasksLinkDialog__task-warning"
        >
          <v-icon
            color="warning"
            small
            v-text="'mdi-alert-circle-outline'"
          />
          {{ $t('integration.alreadyLinked') }}
        </div>
      </v-card>
    </div>

    <template #footer="{ close }">
      <v-spacer />
      <v-btn
        :disabled="selectedTaskKey == null"
        :loading="linking"
        depressed
        color="primary"
        @click="link"
      >
        <v-icon
          class="mr-2"
          v-text="'mdi-link'"
        />
        {{ $t('integration.LinkTask') }}
      </v-btn>
      <v-btn
        :disabled="linking"
        outlined
        color="primary"
        class="ml-2"
        @click="close"
      >
        {{ $t('layout.Cancel') }}
      </v-btn>
    </template>
  </ModalLayout>
</template>

<script>
import { ModalLayout } from '@hexway/shared-front'
import { throttle } from 'throttle-debounce'

import { IntegrationService } from '../api'
import { JIRA } from '../constants'
import { handleError } from '../helpers'
import IssueExportRecord from '../store/orm/issueExportRecord'

export default {
  name: 'ExportTasksLinkDialog',

  components: {
    ModalLayout,
  },

  props: {
    dialogInstance: { type: Object, required: true },
    projectId: { type: String, required: true },
    integrationId: { type: String, required: true },
    issueIds: { type: Array, required: true },
  },

  data() {
    return {
      searching: false, // are we querying tasks right now?
      queryNumber: 0, // which time do we search?
      searchQuery: '',
      // Jira-tasks are searched only in this component,
      // so it is okay not to keep them in store for now
      // Type: JiraSearchIssuesResponseSchema[]
      // Example value:
      // [{
      //   id: '12345', // yes, it's a numeric string
      //   displayName: 'OMG-42: Some XSS',
      //   key: 'OMG-42',
      //   summary: 'Some XSS',
      //   alreadyLinked: false,
      // }, { ... }, ... ]
      searchTasksRes: null,
      selectedTaskKey: null,

      linking: false,
    }
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    integration() {
      return this.$store.getters['integration/get'](this.integrationId)
    },

    jiraProject() {
      const { integration } = this
      if (!integration) return null
      if (integration.integrationCode !== JIRA) return null
      if (!integration.jiraProjectKey || !integration.meta) return null
      return integration.meta.projects
        .find(({ key }) => key === integration.jiraProjectKey) || null
    },

    jiraIssueType() {
      const { integration, jiraProject: project } = this
      if (!project || !integration.jiraIssueType) return null

      return project.issuetypes
        .find(({ name, id }) => {
          if (integration.jiraIssueType?.id && id === integration.jiraIssueType.id) return true
          // fixme: explain/fix this, no idea what's happening here, but looks bad
          if (!integration.jiraIssueType?.id && name === (integration.jiraIssueType?.name || integration.jiraIssueType)) return true
        }) || null
    },

    taskMeta() {
      const { jiraIssueType } = this

      if (jiraIssueType) {
        return {
          taskTypeIconUrl: jiraIssueType.iconUrl,
          taskTypeName: jiraIssueType.name,
          assigneeAvatarUrl: require('../assets/images/jira-unassigned.png'),
          assigneeName: 'Automatic',
        }
      }

      return {
        taskTypeIconUrl: null,
        taskTypeName: 'Task',
        assigneeAvatarUrl: null,
        assigneeName: null,
      }
    },
  },

  watch: {
    integrationId: {
      immediate: true,
      async handler(integrationId) {
        if (integrationId) await this.fetchIntegrationMeta()
      },
    },

    searchQuery() {
      this.searchTasksRes = null
      this.selectedTaskKey = null
      this.searchThrottled()
    },

    isOpen: {
      immediate: true,
      handler(isOpen) {
        this.$store.commit('defaultLayout/setDisableGlobalShortcuts', isOpen)
      },
    },
  },

  methods: {
    async ensureIntegration() {
      await this.$store.dispatch('integration/getForProject', {
        projectId: this.projectId,
        reload: false,
      })
      if (!this.integration) throw new Error('Programming error')
      return this.integration
    },

    async fetchIntegrationMeta() {
      const { integrationCode } = await this.ensureIntegration()
      if (integrationCode === JIRA) return this.fetchJiraMeta()
      throw new Error('Unhandled integration type: ' + integrationCode)
    },

    fetchJiraMeta() {
      const { $store, integrationId } = this
      return $store.dispatch('integration/getJiraMeta', { integrationId, reload: false })
    },

    async search() {
      const { integrationId, searchQuery } = this
      if (!searchQuery) {
        this.searchTasksRes = null
        return
      }
      const n = ++this.queryNumber
      this.searching = true
      try {
        const res = await IntegrationService.searchIssuesJira({
          integrationId,
          query: searchQuery,
        })
        if (n === this.queryNumber) {
          this.searchTasksRes = res
        }
      } catch (e) {
        await handleError(this.$store, e, 'Error searching Jira tasks')
      } finally {
        if (n === this.queryNumber) {
          this.searching = false
        }
      }
    },

    searchThrottled: throttle(1000, false, function() {
      return this.search()
    }),

    async link() {
      const { issueIds, integrationId, selectedTaskKey } = this
      if (!selectedTaskKey) return
      if (!issueIds?.length) throw new Error('Programming error: no local issues selected for linking')
      this.linking = true
      try {
        await Promise.all(issueIds.map(async issueId => {
          // TODO: move this request to store
          const linkRes = await IntegrationService.linkLocalWithExternalJiraIssue({
            integrationId,
            body: {
              localIssueID: issueId,
              jiraIssueKey: selectedTaskKey,
            },
          })
          // TODO: move this manipulation to store
          const exportRecord = linkRes.exportRecord
          exportRecord.issueId = issueId
          await IssueExportRecord.insertOrUpdate({ data: exportRecord })
        }))
      } catch (e) {
        await handleError(this.$store, e, 'Error linking Jira tasks')
        return
      } finally {
        this.linking = false
      }
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('integration.SuccessfullyLinked'),
        prependIcon: 'mdi-check-circle-outline',
        prependIconColor: 'success',
      })
      this.isOpen = false
    },
  },
}
</script>

<style lang="sass" scoped>
.ExportTasksLinkDialog

  &__content
    // minimal dialog height, minus header height, minus vertical paddings
    min-height: 444px - 60px - 24px * 2
    width: 100%

  &__summary
    height: 84px
    margin-bottom: 20px

  &__meta
    font-size: 16px
    line-height: 24px
    display: flex

  &__meta-label
    color: #A09EB9
    margin-right: 10px

  &__title
    font-size: 32px
    font-weight: 500
    line-height: 44px
    letter-spacing: 0.005em
    margin-top: 16px

  &__task-card
    padding: 16px
    font-size: 20px
    font-weight: 500
    line-height: 32px
    letter-spacing: 0.005em
    display: flex
    align-items: flex-start

    margin-bottom: 20px

  &__task-warning
    margin-left: auto

    font-size: 14px
    font-weight: 400
    line-height: 32px
    letter-spacing: 0.005em
</style>
